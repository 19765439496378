"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const sudoku_core_1 = require("sudoku-core");
const constants_1 = require("sudoku-core/dist/cjs/constants");
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
const utils_1 = require("@src/shared/utils");
const empty_1 = require("rxjs/internal/observable/empty");
const defaultLevel = 1;
const levelInitial = function (level) {
    if ([1, 2, 3, 4, 5].indexOf(level) < 0) {
        level = defaultLevel;
    }
    return level;
};
class SudokuCtrl {
    constructor($scope, $timeout, $location, $q, $filter, ModalServiceFactory, ConfigService, SoundService) {
        var _a;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$q = $q;
        this.$filter = $filter;
        this.ModalServiceFactory = ModalServiceFactory;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.level = levelInitial(parseInt(this.$location.search()['level']));
        this.modes = {
            note: this.$filter('translate')('Pencil'),
            digit: this.$filter('translate')('Pen'),
            mixed: this.$filter('translate')('Combo'),
        };
        this.sudoku = [];
        this.initial = [];
        this.pause = true;
        this.selected = {};
        this.blankCells = undefined;
        this.mistakes = 0;
        this.hints = 0;
        this.game$ = new rxjs_1.Subject();
        this.save$ = new rxjs_1.Subject();
        this.loadGame$ = new rxjs_1.Subject();
        this.debugKey$ = new rxjs_1.Subject();
        this.undoItems = [];
        this.undo$ = new rxjs_1.Subject();
        this.gameFinish = false;
        this.sudokuGenerating = false;
        this.digits = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        this.digitUsed = {};
        this.mode = JSON.parse(localStorage.getItem(`${this.constructor.name}_mode`) || 'null') || 'digit';
        this._startTimer = new rxjs_1.Subject();
        this._stopTimer = new rxjs_1.Subject();
        this.startWith = parseInt(localStorage.getItem(`${this.constructor.name}_startWith`) || '0');
        this.timeRemaining = (0, utils_1.formatTimeRemaining)(this.startWith);
        this.visible$ = (0, rxjs_1.fromEvent)(document, 'visibilitychange').pipe((0, operators_1.startWith)('visible'), (0, operators_1.map)(() => {
            if (document.visibilityState != 'visible') {
                this.pauseGame();
            }
            return document.visibilityState;
        }));
        this._resume = new rxjs_1.BehaviorSubject('visible');
        this.timerWork = false;
        (_a = this.ConfigService.logoLink$) === null || _a === void 0 ? void 0 : _a.pipe((0, operators_1.tap)((currentTarget) => {
            if (!localStorage.getItem(`${this.constructor.name}_game`)) {
                // event.target?.dispatchEvent(e)
                window.location.href = currentTarget.href;
            }
            else {
                this._confirmNewGame((result) => {
                    if (result) {
                        this.save$.next('remove');
                        window.location.href = currentTarget.href;
                    }
                });
            }
        })).subscribe();
    }
    setLevel(level) {
        this.newGame(level);
    }
    setParam(name, value) {
        if (name == 'level') {
            if (value == defaultLevel) {
                this.$location.search('level', null);
            }
            else {
                this.$location.search('level', value);
            }
        }
    }
    newGame(level) {
        this._confirmNewGame((result) => {
            if (result) {
                this.loadGame$.next(null);
                this.game$.next(level || this.level);
                this.save$.next('remove');
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.mistakes = 0;
                this.hints = 0;
                this.undo$.next({ action: 'reset', item: null });
            }
        });
    }
    restart() {
        this._confirmNewGame((result) => {
            if (result) {
                this.save$.next('remove');
                this.loadGame$.next(ng.toJson({
                    level: this.level,
                    sudoku: ng.copy(this.initial),
                    initial: this.initial,
                    solved: this.solved,
                    selectedIndex: 0,
                }));
                this.game$.next(this.level);
                this._startTimer.next(null);
                this.startWith = 0;
                this.timeRemaining = '00:00';
                this.gameFinish = false;
                this.mistakes = 0;
                this.hints = 0;
                this.undo$.next({ action: 'reset', item: null });
            }
        });
    }
    $onInit() {
        this.SoundService.play('win');
        const loadGame$ = this.loadGame$.pipe((0, operators_1.startWith)(localStorage.getItem(`${this.constructor.name}_game`)), (0, operators_1.map)((json) => {
            return json ? JSON.parse(json) : null;
        }), (0, operators_1.shareReplay)());
        this.game$.pipe((0, operators_1.startWith)(this.level), (0, operators_1.withLatestFrom)(loadGame$), (0, operators_1.switchMap)(([level, savedGame]) => {
            // console.log({level, savedGame})
            if (savedGame) {
                return (0, rxjs_1.of)(savedGame);
            }
            else {
                return (0, rxjs_1.defer)(() => {
                    const difficulty = [
                        constants_1.DIFFICULTY_EASY,
                        constants_1.DIFFICULTY_MEDIUM,
                        constants_1.DIFFICULTY_HARD,
                        constants_1.DIFFICULTY_EXPERT,
                        constants_1.DIFFICULTY_MASTER
                    ][level - 1];
                    this.sudokuGenerating = true;
                    return this.$timeout(() => {
                        const board = (0, sudoku_core_1.generate)(difficulty);
                        this.sudokuGenerating = false;
                        return board;
                    });
                }).pipe((0, operators_1.map)((board) => {
                    this.save$.next('remove');
                    const sudoku = board.map((value, i) => {
                        const x = i % 9;
                        const y = Math.ceil((i + 1) / 9) - 1;
                        return {
                            tip: value,
                            internal: {
                                value: null,
                                candidates: [],
                                invalidCandidates: undefined
                            },
                            x: x,
                            y: y,
                            i: i,
                            block: Math.floor(x / 3) + Math.floor(y / 3) * 3,
                            error: 0
                        };
                    });
                    return {
                        level: level,
                        sudoku: sudoku,
                        initial: ng.copy(sudoku),
                        solved: (0, sudoku_core_1.solve)(board),
                        selectedIndex: 0,
                        hints: 0,
                        mistakes: 0,
                    };
                }));
            }
        }), (0, operators_1.tap)((data) => {
            this.level = data.level;
            this.setParam('level', data.level);
            this.solved = data.solved;
            this.sudoku = data.sudoku;
            this.initial = data.initial;
            this.mistakes = data.mistakes;
            this.hints = data.hints;
            this.selected = {};
            this.digitUsed = this.getDigitUsed();
            // if (!data.selectedIndex) {
            //     this.selected = {}
            // } else {
            //     this.select(null, data.selectedIndex)
            // }
            this.blankCells = this.sudoku.filter((item) => !item.tip && !item.internal.value).length;
            this.gameFinish = false;
        })).subscribe();
        const keyup$ = (0, rxjs_1.merge)((0, rxjs_1.fromEvent)(document, 'keyup'), this.debugKey$.pipe((0, operators_1.filter)(([i, e]) => !this.gameFinish && (i != undefined)), (0, operators_1.concatMap)(([i, e]) => {
            return (0, rxjs_1.timer)(20).pipe((0, operators_1.map)(() => {
                this.select(null, i);
                return e;
            }));
        }))).pipe((0, operators_1.filter)(event => !event.repeat), (0, operators_1.filter)(() => (this.selected.i != undefined) && (this.sudoku != undefined) && !this.gameFinish), (0, operators_1.share)());
        keyup$.pipe((0, operators_1.filter)((e) => ['KeyN', 'KeyH', 'KeyU'].indexOf(e.code) != -1), (0, operators_1.tap)((e) => {
            this.$scope.$apply(() => {
                switch (e.code) {
                    case 'KeyN': {
                        this.nextMode();
                        break;
                    }
                    case 'KeyU': {
                        this.undo();
                        break;
                    }
                    case 'KeyH': {
                        this.hint();
                        break;
                    }
                }
            });
        })).subscribe();
        // numpad
        keyup$.pipe((0, operators_1.tap)((e) => {
            // console.log(e)
            const value = parseInt(e.key);
            this.$scope.$apply(() => {
                const oldCell = ng.copy(this.sudoku[this.selected.i]);
                let hasError = false;
                if (!this.gameFinish)
                    this._startTimer.next(true);
                if (!isNaN(value)) {
                    if ((value >= 1) && (value <= 9) && !this.sudoku[this.selected.i].tip) {
                        if (this.mode == 'digit') {
                            if (this.sudoku[this.selected.i].internal.value != value) {
                                if (this.digitUsed[value] < 9) {
                                    this.sudoku[this.selected.i].internal.value = value;
                                    this.SoundService.play('number');
                                }
                            }
                            else {
                                this.sudoku[this.selected.i].internal.value = null;
                                this.SoundService.play('erase');
                            }
                            this.sudoku[this.selected.i].internal.candidates = [];
                        }
                        else if (this.mode == 'note') {
                            this.sudoku[this.selected.i].internal.value = null;
                            if (this.sudoku[this.selected.i].internal.candidates[value - 1] != value) {
                                this.sudoku[this.selected.i].internal.candidates[value - 1] = value;
                                this.SoundService.play('note');
                            }
                            else {
                                this.sudoku[this.selected.i].internal.candidates[value - 1] = null;
                                this.SoundService.play('erase');
                            }
                            // this.sudoku[this.selected.i].internal.candidates[value - 1] = !this.sudoku[this.selected.i].internal.candidates[value - 1] ? value : null
                            this.SoundService.play('note');
                        }
                        else if (this.mode == 'mixed') {
                            if (!this.sudoku[this.selected.i].internal.value && !this.sudoku[this.selected.i].internal.candidates.length) {
                                if (this.digitUsed[value] < 9) {
                                    this.sudoku[this.selected.i].internal.value = value;
                                    this.SoundService.play('number');
                                }
                            }
                            else if (this.sudoku[this.selected.i].internal.candidates.length && this.sudoku[this.selected.i].internal.candidates.indexOf(value) != -1) {
                                this.sudoku[this.selected.i].internal.candidates[value - 1] = null;
                                const candidates = [...this.sudoku[this.selected.i].internal.candidates.filter((item) => item)];
                                if ((candidates.length == 1) && (candidates[0] <= 9)) {
                                    this.sudoku[this.selected.i].internal.candidates = [];
                                    this.sudoku[this.selected.i].internal.value = candidates[0];
                                    this.SoundService.play('number');
                                }
                                if (!candidates.length) {
                                    this.sudoku[this.selected.i].internal.candidates = [];
                                    this.sudoku[this.selected.i].internal.value = null;
                                }
                            }
                            else if (this.sudoku[this.selected.i].internal.value == value) {
                                this.sudoku[this.selected.i].internal.value = null;
                            }
                            else if (this.sudoku[this.selected.i].internal.candidates.indexOf(value) == -1) {
                                this.sudoku[this.selected.i].internal.candidates[this.sudoku[this.selected.i].internal.value - 1] = this.sudoku[this.selected.i].internal.value;
                                this.sudoku[this.selected.i].internal.candidates[value - 1] = value;
                                this.sudoku[this.selected.i].internal.value = null;
                                // if (this.sudoku[this.selected.i].internal.candidates.filter((item:CellValue) => item).length == 1) {
                                //     this.sudoku[this.selected.i].internal.value = value
                                //     this.sudoku[this.selected.i].internal.candidates = []
                                // } else {
                                //     this.sudoku[this.selected.i].internal.value = null
                                // }
                            }
                        }
                        this.sudoku[this.selected.i].internal.invalidCandidates = [];
                    }
                }
                else {
                    if (['Backspace', 'Delete', 'KeyC'].indexOf(e.code) != -1) {
                        if (this.sudoku[this.selected.i].internal.value)
                            this.SoundService.play('erase');
                        this.sudoku[this.selected.i].internal.value = null;
                        this.sudoku[this.selected.i].internal.candidates = [];
                        this.sudoku[this.selected.i].internal.invalidCandidates = [];
                    }
                }
                this.sudoku.forEach((item) => (item.error = 0, item.internal.invalidCandidates = []));
                this.sudoku.filter((item) => item.internal.value || item.internal.candidates.length).forEach((cell) => {
                    this.sudoku.forEach((item, i) => {
                        const value = item.tip || item.internal.value;
                        if (((item.x == cell.x) || (item.y == cell.y) || (item.block == cell.block)) && (item.i != cell.i)) {
                            if (value) {
                                if (value == cell.internal.value) {
                                    item.error = value;
                                    hasError = true;
                                }
                                else if (cell.internal.candidates.indexOf(value) != -1) {
                                    cell.error = value;
                                    cell.internal.invalidCandidates[value - 1] = value;
                                    hasError = true;
                                }
                            }
                            if ((item.i == this.selected.i) && item.tip) {
                                if (cell.internal.candidates.indexOf(item.tip - 1) != -1) {
                                    cell.internal.candidates[item.tip - 1] = null;
                                    cell.internal.invalidCandidates[item.tip - 1] = null;
                                }
                            }
                        }
                    });
                    const nibCells = this.getNibCells(cell);
                    cell.error = nibCells.filter((item) => item.error == cell.internal.value).length > 0 ? cell.internal.value : 0;
                    if ((cell.i == this.selected.i) && cell.internal.value) {
                        nibCells.forEach((item) => {
                            item.internal.candidates[cell.internal.value - 1] = null;
                            if (!item.internal.candidates.some((item) => item != null)) {
                                item.internal.candidates = [];
                            }
                            if (item.internal.invalidCandidates[cell.internal.value - 1])
                                item.internal.invalidCandidates[cell.internal.value - 1] = null;
                        });
                    }
                    hasError = hasError || (cell.error != 0);
                });
                this.blankCells = this.sudoku.filter((item) => !item.tip && !item.internal.value).length;
                this.digitUsed = this.getDigitUsed();
                // console.log(
                //     oldCell,
                //     this.sudoku[this.selected.i],
                //     ng.equals(oldCell, this.sudoku[this.selected.i])
                // )
                if (!ng.equals(oldCell, this.sudoku[this.selected.i])) {
                    if ((e.code != 'Number0') && !this.sudoku[this.selected.i].tip) {
                        this.undo$.next({
                            action: 'push',
                            item: {
                                cell: oldCell,
                                selected: this.selected,
                            }
                        });
                    }
                    this.save$.next('set');
                    if (this.sudoku[this.selected.i].error) {
                        this.mistakes += 1;
                        if (this.ConfigService.cookieSettings.highlight_duplicates || this.ConfigService.cookieSettings.highlight_wrong_notice)
                            this.SoundService.play('error');
                    }
                }
                const win = !hasError && !this.blankCells;
                if (win || (this.ConfigService.cookieSettings.pro_mode && (this.mistakes >= 3))) {
                    this.endGame(win);
                }
            });
        })).subscribe();
        this.save$.pipe((0, operators_1.tap)((action) => {
            if (action == 'set') {
                localStorage.setItem(`${this.constructor.name}_game`, ng.toJson({
                    level: this.level,
                    sudoku: this.sudoku,
                    initial: this.initial,
                    solved: this.solved,
                    selectedIndex: this.selected.i,
                    hints: this.hints,
                    mistakes: this.mistakes,
                    undoItems: this.undoItems,
                }));
            }
            else if (action == 'remove') {
                localStorage.removeItem(`${this.constructor.name}_game`);
                localStorage.removeItem(`${this.constructor.name}_startWith`);
            }
        })).subscribe();
        this._startTimer.pipe((0, operators_1.tap)((value) => {
            // console.log('_startTimer', value)
            if (!value) {
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(this.startWith);
            }
        }), (0, operators_1.distinctUntilChanged)(), (0, operators_1.switchMap)((value) => {
            if (value) {
                return this._makeClock();
            }
            return empty_1.EMPTY;
        })).subscribe();
        this.undo$.pipe((0, operators_1.startWith)(null), (0, operators_1.withLatestFrom)(loadGame$), (0, operators_1.switchMap)(([undo, savedGame]) => {
            var _a;
            if (undo) {
                return (0, rxjs_1.of)(undo);
            }
            else if ((_a = savedGame === null || savedGame === void 0 ? void 0 : savedGame.undoItems) === null || _a === void 0 ? void 0 : _a.length) {
                return (0, rxjs_1.from)(savedGame.undoItems.map((item) => ({ action: 'push', item: item })));
            }
            return rxjs_1.NEVER;
        }), (0, operators_1.filter)(item => item != null), (0, operators_1.scan)((acc, value) => {
            if (value.action == 'push') {
                acc.push(value.item);
            }
            else if (value.action == 'pop') {
                const undo = acc.pop();
                this.selected = undo.selected;
                this.SoundService.play('undo');
                if (!this.sudoku[this.selected.i].tip) {
                    this.sudoku[this.selected.i] = undo.cell;
                    this.debugKey$.next([this.selected.i, { repeat: false, code: `Number0`, key: 0 }]);
                }
            }
            else {
                acc = [];
            }
            return acc;
        }, []), (0, operators_1.tap)((undoItems) => {
            // console.log(JSON.stringify(undoItems))
            this.undoItems = undoItems;
        })).subscribe();
    }
    select($event, i) {
        if (!this.gameFinish) {
            const x = i % 9;
            const y = Math.ceil((i + 1) / 9) - 1;
            this.selected = { i: i, x: x, y: y, block: Math.floor(x / 3) + Math.floor(y / 3) * 3, init: true };
            this.save$.next('set');
            if ($event) {
                this._startTimer.next(true);
            }
        }
    }
    keyPress(digit) {
        this.debugKey$.next([this.selected.i, { repeat: false, code: `Number${digit}`, key: digit }]);
    }
    erase() {
        this.debugKey$.next([this.selected.i, { repeat: false, code: `Delete` }]);
    }
    hint() {
        var _a;
        if (((_a = this.solved) === null || _a === void 0 ? void 0 : _a.board) && !this.sudoku[this.selected.i].tip && !this.gameFinish && (this.selected.i != undefined) && (this.hints < 3)) {
            this.SoundService.play('hint');
            this.sudoku[this.selected.i].tip = this.solved.board[this.selected.i];
            this.sudoku[this.selected.i].internal.value = null;
            this.sudoku[this.selected.i].internal.candidates = [];
            this.sudoku[this.selected.i].internal.invalidCandidates = [];
            this.hints += 1;
            this.debugKey$.next([this.selected.i, { repeat: false, code: `Number0`, key: 0 }]);
        }
    }
    undo() {
        this.undo$.next({ action: 'pop', item: null });
    }
    // switchMode(): void {
    //     this.mode = this.mode == 'digit' ? 'note' : 'digit'
    // }
    nextMode() {
        switch (this.mode) {
            case "digit": {
                this.mode = 'note';
                break;
            }
            case "note": {
                this.mode = 'mixed';
                break;
            }
            case "mixed": {
                this.mode = 'digit';
                break;
            }
        }
        localStorage.setItem(`${this.constructor.name}_mode`, JSON.stringify(this.mode));
    }
    endGame(win) {
        this._stopTimer.next(null);
        win ? this.SoundService.play('win') : this.SoundService.play('lose');
        this.gameFinish = true;
        this.save$.next('remove');
        this.ModalServiceFactory.open({
            id: 'game_status',
            template: require("./end_game.ng.html"),
            component: "alert-comp",
            scope: this.$scope,
            extraContext: {
                timeRemaining: this.timeRemaining,
                mistakes: this.mistakes,
                hints: this.hints,
                win: win,
                // level: this.level,
                cookieSettings: this.ConfigService.cookieSettings
            }
        }).then((result) => {
            if (result == 'newGame') {
                this.newGame();
            }
            else if (result == 'restart') {
                this.restart();
            }
        });
    }
    pauseGame() {
        if (!this.gameFinish && this.ConfigService.cookieSettings.show_timer) {
            this.pause = true;
            this._resume.next('hidden');
            this.ModalServiceFactory.open({
                id: 'paused',
                component: "pause-comp",
                scope: this.$scope,
                strategy: "if_close_all"
            }).then(() => {
                this.pause = false;
                this._resume.next('visible');
            });
        }
    }
    _makeClock() {
        return (0, rxjs_1.combineLatest)([this.visible$, this._resume]).pipe((0, operators_1.switchMap)(([v1, v2]) => {
            // console.log(v1, v2)
            if ((v1 == 'visible') && (v2 == 'visible')) {
                return (0, rxjs_1.timer)(0, 1000).pipe((0, operators_1.withLatestFrom)((0, rxjs_1.of)(this.startWith)));
            }
            return empty_1.EMPTY;
        }), (0, operators_1.map)(([i, startWith]) => {
            const sec = i + startWith;
            this.$timeout(() => {
                this.timerWork = true;
                this.startWith = sec;
                localStorage.setItem(`${this.constructor.name}_startWith`, sec.toString());
                this.timeRemaining = (0, utils_1.formatTimeRemaining)(sec);
            });
            return sec;
        }), (0, operators_1.takeUntil)(this._stopTimer), (0, operators_1.finalize)(() => {
            this.timerWork = false;
        }));
    }
    _confirmNewGame(callback) {
        if (this.gameFinish || !localStorage.getItem(`${this.constructor.name}_game`)) {
            return this.$q.when().then(callback ? callback(true) : null);
        }
        else {
            const pause = this.pause;
            this.pause = true;
            this._stopTimer.next(true);
            return this.ModalServiceFactory.open({
                id: 'nonogram_new_game',
                component: "confirm-comp",
                scope: this.$scope,
                extraContext: {
                    settings: {}
                }
            }).then((result) => {
                if (result) {
                    callback ? callback(result) : null;
                }
                else {
                    this.pause = pause;
                    if (!pause) {
                        this._startTimer.next(null);
                        this._startTimer.next(true);
                    }
                    throw { error: 'cancel' };
                }
            });
        }
    }
    getDigitUsed() {
        return this.sudoku.map(item => item.tip || item.internal.value).reduce(function (acc, curr) {
            return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc;
        }, {});
    }
    // private debug(): void {
    //     if (!this.gameFinish)
    //         var y = 1
    //     this.sudoku.forEach((item, i) => {
    //         if (!item.tip && this.solved?.board && (((this.blankCells || 0) - y) > 1)) {
    //             const value: any = this.solved?.board[i]
    //
    //             this.debugKey$.next([i, {repeat: false, code: `Number${value}`, key: value}])
    //             if (i == 80) {
    //                 console.log(this.solved)
    //             }
    //             y += 1
    //         }
    //     })
    //     this.solved?.board?.forEach((item, i) => {
    //
    //     })
    // }
    getNibCells(cell) {
        return this.sudoku.filter((item) => (item.x == cell.x) || (item.y == cell.y) || (item.block == cell.block));
    }
}
SudokuCtrl.$inject = ['$scope', '$timeout', "$location", "$q", "$filter", 'ModalServiceFactory', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameSudoku', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: SudokuCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['SoundServiceProvider', 'WsServiceProvider', 'ConfigServiceProvider', (SoundServiceProvider, WsServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('sudoku/');
        SoundServiceProvider.setSound({
            'lose': require('./sounds/lose.mp3').default,
            'erase': require('./sounds/erase.mp3').default,
            'error': require('./sounds/error.mp3').default,
            'hint': require('./sounds/hint.mp3').default,
            'win': require('./sounds/win.mp3').default,
            'undo': require('./sounds/undo.mp3').default,
            'number': require('./sounds/number.mp3').default,
            'note': require('./sounds/note.mp3').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            show_timer: true,
            sound_effects: false,
            pro_mode: false,
            show_number_of_cells: true,
            highlight_duplicates: true,
            highlight_wrong_notice: false,
            highlight_same_numbers: true,
            highlight_regions: true,
            auto_candidate_mode: false,
        });
    }]);
